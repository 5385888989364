import { Colors } from '@prezi/dragon';
import React from 'react';
import { restartIconSmall } from '..';
import { Button } from './Common';
export default function RestartWithLearnMoreAboutPrezi(props) {
  return React.createElement(React.Fragment, null, React.createElement(Button, {
    backgroundColor: Colors.blue400,
    type: "filled",
    size: "big",
    onClick: props.onLearnMoreAboutPreziClick,
    label: props.localizedMessages.learnMoreAboutPrezi
  }), React.createElement(Button, {
    size: "small",
    type: "link",
    iconElement: restartIconSmall(),
    onClick: props.onRestart,
    label: props.localizedMessages.restartButtonLabel
  }));
}