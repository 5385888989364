import { Colors } from '@prezi/dragon';
export var ViewToLibraryTheme = {
  buttonColor: Colors.blueLight,
  buttonColorHover: Colors.blue,
  buttonBorderColor: Colors.blueLight,
  buttonTextColor: Colors.white,
  iconFilterColor: 'invert(41%) sepia(43%) saturate(4465%) hue-rotate(358deg) brightness(99%) contrast(92%)',
  CTABorderRadius: '3px'
};
export var GrayViewToLibraryTheme = {
  buttonColor: Colors.neutral80,
  buttonColorHover: Colors.neutral100,
  buttonBorderColor: Colors.neutral80,
  buttonTextColor: Colors.neutral600,
  iconFilterColor: 'invert(41%) sepia(43%) saturate(4465%) hue-rotate(358deg) brightness(99%) contrast(92%)',
  CTABorderRadius: '3px'
};