import * as React from 'react';
import { Colors } from '@prezi/dragon';
var CDN_URL = 'https://assets1.prezicdn.net/frontend-packages/present-viewer/';
import { ctaButtonStyle, restartButtonHoverStyle } from './Styles';
import { centeringBlockStyle, foregroundStyle } from '../Styles';
import { Button } from '../Common';
import { restartIconSmall } from '../Assets';
import styled from 'styled-components';
var mediumSizedButtons = `
    .viewer-common-info-overlay-button-small-filled,
    .viewer-common-info-overlay-button-small-transparent {
        padding: 10px 16px !important;
        font-size: 16px !important;
        .viewer-common-info-overlay-button-label {
            font-size: 16px !important;
            line-height: 1.1 !important;
        }
    }
`;
var FullHeightCenterBlock = styled.div.withConfig({
  displayName: "RestartOverlayWithVideoAd__FullHeightCenterBlock",
  componentId: "fifp0g-0"
})(["height:100%;width:100%;display:flex;flex-direction:column;background:", ";.size-xlarge &{", "}@media screen and (max-width:768px){", "}@media screen and (max-width:576px){.viewer-common-info-overlay-button-small-filled,.viewer-common-info-overlay-button-small-transparent{padding:6px 12px !important;font-size:14px !important;.viewer-common-info-overlay-button-label{font-size:14px !important;line-height:1 !important;}}}"], p => p.theme === 'present' ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48))' : 'linear-gradient(0deg, rgba(5, 18, 37, 1), rgba(5, 18, 37, 0.68))', mediumSizedButtons, mediumSizedButtons);
var InfoOverlayAdBlock = styled.div.withConfig({
  displayName: "RestartOverlayWithVideoAd__InfoOverlayAdBlock",
  componentId: "fifp0g-1"
})(["display:flex;align-items:center;width:90%;margin:20px auto;margin-bottom:0;flex:1;max-width:1200;pointer-events:initial;"]);
var InfoOverlayAdBlockCTA = styled.div.withConfig({
  displayName: "RestartOverlayWithVideoAd__InfoOverlayAdBlockCTA",
  componentId: "fifp0g-2"
})(["flex:1 0 50%;height:100%;display:flex;flex-direction:column;padding-right:24px;justify-content:center;"]);
var mediumSizedText = `
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 18px;
`;
var CtaText = styled.span.withConfig({
  displayName: "RestartOverlayWithVideoAd__CtaText",
  componentId: "fifp0g-3"
})(["font-size:28px;line-height:36px;color:white;margin-bottom:32px;.size-xlarge &{", "}@media screen and (max-width:768px){", "}@media screen and (max-width:576px){font-size:18px;line-height:20px;margin-bottom:16px;}"], mediumSizedText, mediumSizedText);
var AdBlockVideo = styled.div.withConfig({
  displayName: "RestartOverlayWithVideoAd__AdBlockVideo",
  componentId: "fifp0g-4"
})(["flex:1 0 50%;height:100%;"]);
var VideoWrapper = styled.div.withConfig({
  displayName: "RestartOverlayWithVideoAd__VideoWrapper",
  componentId: "fifp0g-5"
})(["max-width:900px;height:100%;width:100%;display:flex;align-items:center;"]);
var VideoAd = styled.video.withConfig({
  displayName: "RestartOverlayWithVideoAd__VideoAd",
  componentId: "fifp0g-6"
})(["width:100%;border-radius:8px;box-shadow:0px 0px 0px 1px rgba(0,0,0,0.08),0px 2px 4px -2px rgba(5,18,37,0.24);filter:drop-shadow(0px 8px 24px rgba(5,18,37,0.32));"]);
var RestartContainer = styled.div.withConfig({
  displayName: "RestartOverlayWithVideoAd__RestartContainer",
  componentId: "fifp0g-7"
})(["padding-bottom:32px;display:flex;align-items:center;pointer-events:initial;"]);
export var RestartOverlayWithVideoAdForeground = props => {
  return React.createElement("div", {
    className: "viewer-common-info-overlay-foreground",
    style: foregroundStyle
  }, React.createElement("div", {
    className: "viewer-common-info-overlay-centering",
    style: centeringBlockStyle
  }, React.createElement(FullHeightCenterBlock, {
    className: "viewer-common-info-overlay-center-block"
  }, React.createElement(InfoOverlayAdBlock, {
    className: "viewer-container-info-overlay-ad-block"
  }, React.createElement(InfoOverlayAdBlockCTA, {
    className: "viewer-container-info-overlay-ad-block-cta"
  }, React.createElement(CtaText, {
    dangerouslySetInnerHTML: {
      __html: props.localizedMessages.mainCtaText
    }
  }), React.createElement(Button, {
    size: "small",
    type: "filled",
    backgroundColor: props.theme === 'present' ? Colors.blueLight : Colors.purple600,
    style: ctaButtonStyle,
    label: props.localizedMessages.ctaButtonText,
    onClick: props.onGetPreziClick
  })), React.createElement(AdBlockVideo, {
    className: "viewer-container-info-overlay-ad-block-video"
  }, React.createElement(VideoWrapper, null, React.createElement(VideoAd, {
    className: "side-video",
    src: `${CDN_URL}video_capabilities.mp4`,
    poster: `${CDN_URL}hero-poster.jpeg`,
    autoPlay: true,
    muted: true,
    loop: true
  })))), React.createElement(RestartContainer, null, React.createElement(Button, {
    type: "link",
    size: "small",
    iconElement: restartIconSmall(),
    onClick: props.onRestart,
    label: props.localizedMessages.restartButtonLabel,
    hoverStyle: restartButtonHoverStyle
  })))));
};