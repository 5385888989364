import * as React from 'react';

import { DesignProject } from './models/';
import { LinkID } from './models/appData';
import { ModalManager } from '@prezi/viewer-common';

export enum DesignViewPageModal {
    NO_MODAL,
    EMBED_MODAL,
}
export interface DesignContext {
    presentationMetadata: DesignProject;
    isAuthenticated: boolean;
    isMobile: boolean;
    linkID: LinkID;
    modalManager: ModalManager;
    activeModal: DesignViewPageModal;
    setActiveModal: (modal: DesignViewPageModal) => void;
    hasPrivateSharing: boolean;
}

export const DesignPageContext = React.createContext<DesignContext>({
    presentationMetadata: new DesignProject(),
    isAuthenticated: false,
    isMobile: false,
    linkID: null,
    modalManager: new ModalManager(),
    activeModal: DesignViewPageModal.NO_MODAL,
    setActiveModal: (_modal: DesignViewPageModal) => {},
    hasPrivateSharing: false,
});
