import * as React from 'react';
import { CTAButton } from './Styles';
export var CTA = props => {
  var className = props.className || '';
  className += props.isGray ? ' gray' : '';
  return React.createElement(CTAButton, {
    onClick: props.onClick,
    href: props.href,
    className: className,
    "data-lookup": props.lookupName,
    target: props.openNewTab ? '_blank' : undefined
  }, props.innerText);
};