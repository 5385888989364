import styled from 'styled-components';
import { Colors } from '@prezi/dragon';
import { BaseTheme } from '../../themes/baseTheme';
export var EmbedGeneratorModal = styled.div.attrs({
  className: 'embed-generator'
}).withConfig({
  displayName: "Styles__EmbedGeneratorModal",
  componentId: "sc-1yxrs7s-0"
})(["background-color:", ";border-radius:4px;max-width:600px;width:95%;height:auto;max-height:90vh;overflow-y:auto;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);display:flex;flex-direction:column;justify-content:space-between;padding:39px;"], Colors.white);
export var CloseButton = styled.div.withConfig({
  displayName: "Styles__CloseButton",
  componentId: "sc-1yxrs7s-1"
})(["background-color:transparent;border:none;transition:200ms all;height:24px;width:24px;position:absolute;top:12px;right:12px;display:flex;align-items:center;justify-content:center;:hover{opacity:0.6;cursor:pointer;}"]);
export var CloseIcon = styled.div.withConfig({
  displayName: "Styles__CloseIcon",
  componentId: "sc-1yxrs7s-2"
})(["width:10px;"]);
export var HeaderText = styled.h1.withConfig({
  displayName: "Styles__HeaderText",
  componentId: "sc-1yxrs7s-3"
})(["font-weight:normal;color:", ";font-size:40px;line-height:48px;margin-bottom:32px;"], Colors.neutral700);
export var SubtitleText = styled.h3.withConfig({
  displayName: "Styles__SubtitleText",
  componentId: "sc-1yxrs7s-4"
})(["font-size:12px;line-height:16px;color:#374050;text-transform:uppercase;margin-bottom:16px;font-weight:600;letter-spacing:0.5px;"]);
export var DimensionsExplainerText = styled.p.withConfig({
  displayName: "Styles__DimensionsExplainerText",
  componentId: "sc-1yxrs7s-5"
})(["font-weight:500;font-size:16px;line-height:24px;color:", ";margin-bottom:0;"], Colors.neutral500);
export var DimensionDiv = styled.div.withConfig({
  displayName: "Styles__DimensionDiv",
  componentId: "sc-1yxrs7s-6"
})(["display:flex;justify-content:space-around;margin-bottom:16px;"]);
export var DimensionInput = styled.input.withConfig({
  displayName: "Styles__DimensionInput",
  componentId: "sc-1yxrs7s-7"
})(["height:48px;width:70px;border-radius:4px;margin-left:6px;margin-right:6px;padding:6px 12px;border:1px solid ", ";"], Colors.gray4);
export var EmbedCodeTextArea = styled.textarea.attrs({
  readOnly: true
}).withConfig({
  displayName: "Styles__EmbedCodeTextArea",
  componentId: "sc-1yxrs7s-8"
})(["height:128px;background-color:", ";resize:none;border-radius:3px;border:1px solid #c2c7cd;color:", ";margin-bottom:32px;padding:15px;"], Colors.neutral100, Colors.neutral500);
export var CopyToClipBoardButton = styled.button.attrs({
  className: 'btn-xs'
}).withConfig({
  displayName: "Styles__CopyToClipBoardButton",
  componentId: "sc-1yxrs7s-9"
})(["background-color:", ";border-radius:4px;font-size:14px;line-height:16px;padding:12px 24px;margin-left:auto;margin-right:auto;:hover{background-color:", ";}"], props => props.theme.buttonColor, props => props.theme.buttonColorHover);
CopyToClipBoardButton.defaultProps = {
  theme: BaseTheme
};