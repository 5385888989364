function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); return Constructor; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); if (superClass) _setPrototypeOf(subClass, superClass); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _possibleConstructorReturn(self, call) { if (call && (typeof call === "object" || typeof call === "function")) { return call; } else if (call !== void 0) { throw new TypeError("Derived constructors may only return object or undefined"); } return _assertThisInitialized(self); }

function _assertThisInitialized(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }

import * as React from 'react';
import * as Styles from './Styles';
import { ActionMode, PreviewState } from '../models/InfoOverlay';
import { ResponsiveMode } from '../models/common';
import { LoadingOverlay } from './LoadingOverlay';
import { RestartOverlay } from './RestartOverlay';
import { StartOverlay } from './StartOverlay';
export var InfoOverlay = function (_React$Component) {
  _inherits(InfoOverlay, _React$Component);

  var _super = _createSuper(InfoOverlay);

  function InfoOverlay(props) {
    var _this;

    _classCallCheck(this, InfoOverlay);

    _this = _super.call(this, props);

    _this.updateResponsiveMode = () => {
      var responsiveMode = _this.largeMediaQuery.matches ? ResponsiveMode.LARGE : _this.middleMediaQuery.matches ? ResponsiveMode.MIDDLE : ResponsiveMode.SMALL;

      _this.setState({
        responsiveMode
      });
    };

    _this.state = {
      responsiveMode: ResponsiveMode.LARGE
    };
    return _this;
  }

  _createClass(InfoOverlay, [{
    key: "UNSAFE_componentWillMount",
    value: function UNSAFE_componentWillMount() {
      this.largeMediaQuery = window.matchMedia('(min-width: 1024px) and (min-height: 516px)');
      this.largeMediaQuery.addListener(this.updateResponsiveMode);
      this.middleMediaQuery = window.matchMedia('(min-width: 768px) and (min-height: 236px)');
      this.middleMediaQuery.addListener(this.updateResponsiveMode);
      this.updateResponsiveMode();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.middleMediaQuery.removeListener(this.updateResponsiveMode);
    }
  }, {
    key: "getBaseOverlayForegroundProps",
    value: function getBaseOverlayForegroundProps() {
      return {
        responsiveMode: this.state.responsiveMode,
        title: this.props.title,
        byOwner: this.props.byOwner,
        lastModifiedDateString: this.props.lastModifiedDateString,
        owner: this.props.owner
      };
    }
  }, {
    key: "getOverlayForeground",
    value: function getOverlayForeground() {
      var _this$props = this.props,
          onStart = _this$props.onStart,
          onRestart = _this$props.onRestart,
          localizedMessages = _this$props.localizedMessages,
          loadPercentage = _this$props.loadPercentage,
          onViewOnPreziDotComClick = _this$props.onViewOnPreziDotComClick,
          onLearnMoreAboutPreziClick = _this$props.onLearnMoreAboutPreziClick;
      var props = this.getBaseOverlayForegroundProps();

      switch (this.props.mode) {
        case ActionMode.START:
          if (this.props.customStartForeground) {
            return this.props.customStartForeground(props);
          }

          return React.createElement(StartOverlay, _extends({}, props, {
            onStart: onStart,
            localizedMessages: localizedMessages,
            onViewOnPreziDotComClick: onViewOnPreziDotComClick
          }));

        case ActionMode.LOADING:
          if (this.props.customLoadingForeground) {
            return this.props.customLoadingForeground(props);
          }

          return React.createElement(LoadingOverlay, _extends({}, props, {
            loadPercentage: loadPercentage,
            localizedMessages: localizedMessages
          }));

        case ActionMode.RESTART:
          if (this.props.customRestartForeground) {
            return this.props.customRestartForeground(this.getBaseOverlayForegroundProps());
          }

          return React.createElement(RestartOverlay, _extends({}, props, {
            onRestart: onRestart,
            localizedMessages: localizedMessages,
            onLearnMoreAboutPreziClick: onLearnMoreAboutPreziClick
          }));

        default:
          return null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      var className = 'viewer-common-info-overlay';

      if (this.props.className) {
        className += ` ${this.props.className}`;
      }

      return React.createElement("div", {
        className: className
      }, React.createElement(Styles.commonAnimationKeyframes, null), React.createElement(Styles.hoverStyles, null), React.createElement(Styles.infoOverlayTransitions, null), React.createElement(Preview, this.props), React.createElement(GradientBackground, this.props), this.getOverlayForeground());
    }
  }]);

  return InfoOverlay;
}(React.Component);

function GradientBackground(props) {
  if (props.customGradientBackground) {
    return props.customGradientBackground(_objectSpread({}, props));
  }

  var gradientString = 'linear-gradient(to right, rgba(21,34,53,0.85) 0, rgba(21,34,53,0.7) 100%)';
  var backgroundColor = props.previewState === PreviewState.SHOW || props.previewState === PreviewState.FALLBACK ? gradientString : 'rgba(21,34,53,1)';
  var style = {
    background: backgroundColor,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2
  };
  return React.createElement("div", {
    className: "viewer-common-gradient-background",
    style: style
  });
}

function Preview(props) {
  if (props.customPreview) {
    return props.customPreview(_objectSpread({}, props));
  }

  var previewUrl = props.previewUrl,
      fallbackPreviewUrl = props.fallbackPreviewUrl,
      previewState = props.previewState;

  switch (previewState) {
    case PreviewState.LOADING:
      return null;

    case PreviewState.SHOW:
      return React.createElement(BlurredBackgroundImage, {
        url: previewUrl
      });

    case PreviewState.FALLBACK:
      return fallbackPreviewUrl ? React.createElement(BlurredBackgroundImage, {
        url: fallbackPreviewUrl
      }) : null;

    default:
      return null;
  }
}

function BlurredBackgroundImage(props) {
  var containerStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden'
  };
  var style = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    filter: 'blur(10px)',
    background: `url(${props.url}) center center / cover no-repeat`,
    zIndex: 1
  };
  return React.createElement("div", {
    style: containerStyle
  }, React.createElement("div", {
    className: "viewer-common-preview",
    style: style
  }));
}