import "core-js/modules/es.array.map";
import "regenerator-runtime/runtime";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); return Constructor; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); if (superClass) _setPrototypeOf(subClass, superClass); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _possibleConstructorReturn(self, call) { if (call && (typeof call === "object" || typeof call === "function")) { return call; } else if (call !== void 0) { throw new TypeError("Derived constructors may only return object or undefined"); } return _assertThisInitialized(self); }

function _assertThisInitialized(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }

import * as React from 'react';
import { CloseButton, CloseIcon } from '../EmbedGenerator/Styles';
import { CloseIconSvg, COPY_SUCCESS_MESSAGE_TIMEOUT_IN_MSEC } from '../EmbedGenerator/EmbedGenerator';
import { SocialMediaButton } from '../Buttons/SocialMediaButton';
import { ModalWrapper, ModalTitle, SubHeading, LinkInput, ShareLinkWrapper, CopyLinkButton, SocialButtonsWrapper } from './Styles';
import ToolTip from '../Tooltip/Tooltip';
import { SocialNetwork } from '../../api/socialNetwork';
export var SocialShareModalBase = function (_React$Component) {
  _inherits(SocialShareModalBase, _React$Component);

  var _super = _createSuper(SocialShareModalBase);

  function SocialShareModalBase(props) {
    var _this;

    _classCallCheck(this, SocialShareModalBase);

    _this = _super.call(this, props);

    _this.copyLinkToClipboard = function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(_) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.input.select();

                document.execCommand('copy');

                _this.props.logCopyShareLink();

                _this.setState({
                  showCopySuccessMessage: true
                });

                setTimeout(() => {
                  _this.setState({
                    showCopySuccessMessage: false
                  });
                }, COPY_SUCCESS_MESSAGE_TIMEOUT_IN_MSEC);

              case 5:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();

    _this.state = {
      showCopySuccessMessage: false
    };
    return _this;
  }

  _createClass(SocialShareModalBase, [{
    key: "getSocialNetworksWithTooltips",
    value: function getSocialNetworksWithTooltips() {
      return [[SocialNetwork.FACEBOOK, this.props.text.tooltipFacebook], [SocialNetwork.LINKEDIN, this.props.text.tooltipLinkedIn], [SocialNetwork.TWITTER, this.props.text.tooltipTwitter], [SocialNetwork.GOOGLE_CLASSROOM, this.props.text.tooltipGoogleClassroom]];
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(ModalWrapper, null, React.createElement(CloseButton, {
        onClick: this.props.onCloseModal
      }, React.createElement(CloseIcon, null, React.createElement(CloseIconSvg, null))), React.createElement(ModalTitle, null, this.props.text.modalTitle), React.createElement(SocialButtonsWrapper, null, this.getSocialNetworksWithTooltips().map(_ref2 => {
        var _ref3 = _slicedToArray(_ref2, 2),
            network = _ref3[0],
            tooltipText = _ref3[1];

        return React.createElement(SocialMediaButton, {
          oid: this.props.oid,
          logSocialShare: this.props.logSocialShare,
          key: network,
          title: this.props.title,
          tooltipText: tooltipText,
          tags: this.props.tags,
          network: network
        });
      })), React.createElement(SubHeading, null, this.props.text.linkSubheading), React.createElement(ShareLinkWrapper, null, React.createElement(ToolTip, {
        type: 'top',
        visible: this.state.showCopySuccessMessage,
        tooltipText: this.props.text.copiedTooltip
      }), React.createElement(LinkInput, {
        ref: input => this.input = input,
        readOnly: true,
        value: this.props.url || window.location.href,
        "aria-label": "Modify content link before copying"
      }), React.createElement(CopyLinkButton, {
        "data-lookup": 'link-network',
        disabled: this.state.showCopySuccessMessage,
        onClick: this.copyLinkToClipboard,
        "aria-label": "Copy web page link"
      }, this.state.showCopySuccessMessage ? this.props.text.copyButtonSuccess : this.props.text.copyButtonDefault)));
    }
  }]);

  return SocialShareModalBase;
}(React.Component);