import { i18n } from '@lingui/core';
import * as Cookies from 'es-cookie';
import catalogs, { plurals } from './locale';

export const getCurrentLanguage = (): string => {
    try {
        return document?.documentElement?.getAttribute('lang') || Cookies.get('django_language') || 'en';
    } catch (e) {
        return 'en';
    }
};

let i18nInitialized = false;
export function setupI18n(locale = getCurrentLanguage(), messages = catalogs) {
    if (!i18nInitialized) {
        i18n.load(messages);
        i18n.loadLocaleData(plurals);
        i18n.activate(locale);
        i18nInitialized = true;
        return i18n;
    } else {
        return i18n;
    }
}
