import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import { EmbedGenerator, EmbedGeneratorProps, getDesignEmbedIframeText } from '@prezi/viewer-common';
import { DesignPageContext, DesignViewPageModal } from '../../context';
import { glassboxWebJs } from '@prezi/client-logger-module';

export const EmbedGeneratorContainer = (props: { preziOid: string }) => {
    const { setActiveModal } = useContext(DesignPageContext);

    const embedProps: EmbedGeneratorProps = {
        oid: props.preziOid,
        getEmbedIframeText: getDesignEmbedIframeText,
        onCopy: () => {
            glassboxWebJs.logCopyEmbedCodeToClipboard({ prezi_oid: props.preziOid, product_type: 'DESIGN' });
        },
        onCloseModal: () => {
            setActiveModal(DesignViewPageModal.NO_MODAL);
        },
        localizedMessages: {
            headerText: t`Embed Prezi Design`,
            heightText: t`Height`,
            widthText: t`Width`,
            pixelText: t`pixels`,
            copyButtonText: t`Copy code to clipboard`,
            copyButtonSuccessText: t`Copied!`,
            dimensionsTitleText: t`Dimensions`,
            codeTitleText: t`Code`,
            responsiveDimensionsText: t`The embedded view will adjust to its container and will adapt to the viewer's screen size.`,
            learnMoreText: t`Read more`,
        },
        onlyResponsiveDimensionsAllowed: true,
        learnMoreUrl: 'https://support.prezi.com/hc/articles/360053442394-Embedding-your-Prezi-Design-content',
    };

    return <EmbedGenerator {...embedProps} />;
};
