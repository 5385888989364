import * as React from 'react';

export interface SpinnerProps {
    size: string;
    color?: string;
    children?: React.ReactNode | React.ReactNode[];
}

export const Spinner = (props: SpinnerProps) => {
    const color = props.color || 'blue';

    return (
        <div className={'loader-wrap ' + props.size}>
            <div className={'loader-circle loader-circle-' + color} />
            <div className="loader-line-mask">
                <div className={'loader-line loader-' + color} />
            </div>
            {props.children}
        </div>
    );
};
