import * as React from 'react';
import { restartIcon, restartIconSmall } from './Assets';
import { Label, RoundButton, Button } from './Common';
import { ResponsiveMode } from '../models/common';
import { centeringBlockStyle, foregroundStyle } from './Styles';
import { MetadataBlock } from './MetadataBlock';
import RestartWithLearnMoreAboutPrezi from './RestartWithViewOnPrezi';
export var RestartOverlay = props => {
  return React.createElement("div", {
    className: "viewer-common-info-overlay-foreground",
    style: foregroundStyle
  }, React.createElement(MetadataBlock, {
    title: props.title,
    byOwner: props.byOwner,
    lastModifiedDateString: props.lastModifiedDateString,
    owner: props.owner,
    responsiveMode: props.responsiveMode
  }), React.createElement("div", {
    className: "viewer-common-info-overlay-centering",
    style: centeringBlockStyle
  }, React.createElement("div", {
    className: "viewer-common-info-overlay-center-block"
  }, props.onLearnMoreAboutPreziClick ? React.createElement(RestartWithLearnMoreAboutPrezi, props) : props.responsiveMode === ResponsiveMode.SMALL ? React.createElement(Button, {
    size: "small",
    type: "outline",
    iconElement: restartIconSmall(),
    onClick: props.onRestart,
    label: props.localizedMessages.restartButtonLabel
  }) : React.createElement("div", null, React.createElement(RoundButton, {
    iconElement: restartIcon(),
    onClick: props.onRestart
  }), React.createElement(Label, {
    text: props.localizedMessages.restartButtonLabel
  })))));
};