import { SocialShareModalProps } from '@prezi/viewer-common';
import { DesignProject } from '../../models/DesignProject';
import { t } from '@lingui/macro';
import { glassboxWebPrezigram } from '@prezi/client-logger-module';

export const extractSocialShareModalProps = (presentationMetadata: DesignProject): SocialShareModalProps => {
    return {
        oid: presentationMetadata.oid,
        title: presentationMetadata.title,
        tags: [],
        logCopyShareLink: () => {
            glassboxWebPrezigram.logCopyPublishLink({ prezi_oid: presentationMetadata.oid });
        },
        logSocialShare: network => {
            glassboxWebPrezigram.logSharePublish({
                prezi_oid: presentationMetadata.oid,
                share_type: network,
            });
        },
        text: {
            modalTitle: t`Share Design`,
            linkSubheading: t`Link`,
            copiedTooltip: t`Copied`,
            copyButtonDefault: t`Copy`,
            copyButtonSuccess: t`Copied`,
            tooltipFacebook: t`Facebook`,
            tooltipLinkedIn: t`LinkedIn`,
            tooltipTwitter: t`Twitter`,
            tooltipGoogleClassroom: t`Google Classroom`,
        },
    };
};
