import * as React from 'react';

import { API, CurationButton } from '@prezi/viewer-common';

export class CurationButtonComponent extends React.Component<{}, { hasTags: boolean }> {
    constructor(props: {}) {
        super(props);
        this.state = {
            hasTags: false,
        };
    }

    componentDidMount() {
        API.AdminTools.listenToAdminToolsTagStatusEvent(hasTagsLoaded => this.setState({ hasTags: hasTagsLoaded }));
    }

    onClick = () => {
        API.AdminTools.invokeAdminToolsUI();
    };

    render() {
        return <CurationButton onClick={this.onClick} curationExists={this.state.hasTags} tooltipText={'Tag'} />;
    }
}
