import { FeatureType } from './FeatureType';

export type FeatureMap = Map<string, boolean>;

let features: FeatureMap | null = null;

export function init(featureMap: { [key: string]: boolean }) {
    features = new Map();
    Object.keys(featureMap).forEach((key: string) => {
        features!.set(key, featureMap[key]);
    });
}

export function isActive(feature: FeatureType): boolean {
    if (features != null) {
        const featureValue = features.get(feature);
        if (featureValue == null) {
            return false;
        }
        return featureValue;
    } else {
        throw Error('Features are not initialized yet');
    }
}
