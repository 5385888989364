import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(21, 34, 53, 0.8);
    z-index: 50;
`;

const CenteredContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export interface FullScreenOverlayProps {
    isVisible: boolean;
}

export const FullScreenOverlay: React.SFC<FullScreenOverlayProps> = props => {
    if (!props.isVisible) {
        return null;
    } else {
        return (
            <Overlay id="full-screen-overlay">
                <CenteredContainer>{props.children}</CenteredContainer>
            </Overlay>
        );
    }
};
