import * as React from 'react';
import { playIcon, playIconSmall } from './Assets';
import { Colors } from '@prezi/dragon';
import { Label, RoundButton, Button } from './Common';
import { ResponsiveMode } from '../models/common';
import { centeringBlockStyle, foregroundStyle } from './Styles';
import { MetadataBlock } from './MetadataBlock';
import { ViewOnPrezi } from './ViewOnPrezi';
export var StartOverlay = props => {
  return React.createElement("div", {
    className: "viewer-common-info-overlay-foreground",
    style: foregroundStyle
  }, React.createElement(MetadataBlock, {
    title: props.title,
    byOwner: props.byOwner,
    lastModifiedDateString: props.lastModifiedDateString,
    owner: props.owner,
    responsiveMode: props.responsiveMode
  }), props.onViewOnPreziDotComClick && React.createElement(ViewOnPrezi, {
    onLinkClick: props.onViewOnPreziDotComClick,
    localizedMessage: props.localizedMessages.viewOnPreziDotCom
  }), React.createElement("div", {
    className: "viewer-common-info-overlay-centering",
    style: centeringBlockStyle
  }, React.createElement("div", {
    className: "viewer-common-info-overlay-center-block"
  }, props.responsiveMode === ResponsiveMode.SMALL ? React.createElement(Button, {
    type: "filled",
    size: "small",
    iconElement: playIconSmall(),
    backgroundColor: Colors.blueLight,
    onClick: props.onStart,
    label: props.localizedMessages.presentButtonLabel
  }) : React.createElement("div", null, React.createElement(RoundButton, {
    iconElement: playIcon(),
    backgroundColor: Colors.blueLight,
    onClick: props.onStart
  }), React.createElement(Label, {
    text: props.localizedMessages.presentButtonLabel
  })))));
};