export var ActionMode;

(function (ActionMode) {
  ActionMode["START"] = "start";
  ActionMode["LOADING"] = "loading";
  ActionMode["RESTART"] = "restart";
})(ActionMode || (ActionMode = {}));

export var PreviewState;

(function (PreviewState) {
  PreviewState["NONE"] = "none";
  PreviewState["LOADING"] = "loading";
  PreviewState["SHOW"] = "show";
  PreviewState["FALLBACK"] = "fallback";
})(PreviewState || (PreviewState = {}));