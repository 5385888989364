import { ExternalData } from '.';
import { User } from './User';

export type UserPermissions = {
    canMakeCopy: boolean;
    canSetPrivate: boolean;
    [permissionName: string]: unknown;
};

export type PreziPrivacy = 'public' | 'hidden' | 'private';

export class DesignProject {
    public description!: string;
    public title!: string;
    public oid!: string;
    public createdAt!: string;
    public modifiedAt!: string;
    public owner!: User;
    public likedByMe!: boolean;
    public likeCount!: number;
    public makeACopyRedirectUrl!: string;
    public userPermissions: UserPermissions;
    public privacy: PreziPrivacy;
    public viewCount!: number;

    static createFromAppData(appData: ExternalData) {
        const { presentationMetadata } = appData;
        const designProject = new DesignProject();

        designProject.description = presentationMetadata.description || '';
        designProject.oid = presentationMetadata.oid;
        designProject.title = presentationMetadata.title || '';
        designProject.createdAt = presentationMetadata.createdAt || '';
        designProject.modifiedAt = presentationMetadata.modifiedAt || '';
        designProject.owner = presentationMetadata.owner || new User();
        designProject.likedByMe = presentationMetadata.likedByMe;
        designProject.likeCount = presentationMetadata.likeCount;
        designProject.makeACopyRedirectUrl = presentationMetadata.makeACopyRedirectUrl;
        designProject.userPermissions = presentationMetadata.userPermissions;
        designProject.privacy = presentationMetadata.privacy;
        designProject.viewCount = presentationMetadata.viewCount;
        return designProject;
    }

    constructor() {
        this.description = '';
        this.title = '';
        this.modifiedAt = '';
        this.oid = '';
    }
}
