import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import "regenerator-runtime/runtime";

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); return Constructor; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); if (superClass) _setPrototypeOf(subClass, superClass); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _possibleConstructorReturn(self, call) { if (call && (typeof call === "object" || typeof call === "function")) { return call; } else if (call !== void 0) { throw new TypeError("Derived constructors may only return object or undefined"); } return _assertThisInitialized(self); }

function _assertThisInitialized(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }

import * as React from 'react';
import { ModalBase } from '../ModalBase';
import { CloseButton, CloseIcon, CopyToClipBoardButton, DimensionDiv, DimensionInput, EmbedCodeTextArea, EmbedGeneratorModal, HeaderText, SubtitleText, DimensionsExplainerText } from './Styles';
import { ASPECT_RATIO, DEFAULT_IFRAME_HEIGHT, DEFAULT_IFRAME_WIDTH } from '../../constants';
export var COPY_SUCCESS_MESSAGE_TIMEOUT_IN_MSEC = 3000;
export var CloseIconSvg = () => {
  return React.createElement("svg", {
    width: "10",
    height: "10",
    viewBox: "0 0 10 10",
    fill: "none"
  }, React.createElement("path", {
    opacity: "0.601504",
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M6.48936 5.01779L9.68085 1.81495C10.1064 1.3879 10.1064 0.747331 9.68085 0.320285C9.25532 -0.106762 8.61702 -0.106762 8.19149 0.320285L5 3.52313L1.80851 0.320285C1.38298 -0.106762 0.744681 -0.106762 0.319149 0.320285C-0.106383 0.747331 -0.106383 1.3879 0.319149 1.81495L3.51064 5.01779L0.319149 8.22064C-0.106383 8.64769 -0.106383 9.28826 0.319149 9.7153C0.531915 9.92883 0.815603 10 1.02837 10C1.31206 10 1.59574 9.92883 1.73759 9.7153L4.92908 6.51246L8.12057 9.7153C8.33333 9.92883 8.61702 10 8.82979 10C9.11347 10 9.39716 9.92883 9.53901 9.7153C9.96454 9.28826 9.96454 8.64769 9.53901 8.22064L6.48936 5.01779Z",
    fill: "#374050"
  }));
};
export var DimensionsExplainerLearnMoreLink = props => {
  return React.createElement("a", {
    href: props.url,
    target: "_blank"
  }, props.text);
};
export var EmbedGeneratorBase = function (_React$PureComponent) {
  _inherits(EmbedGeneratorBase, _React$PureComponent);

  var _super = _createSuper(EmbedGeneratorBase);

  function EmbedGeneratorBase(props) {
    var _this;

    _classCallCheck(this, EmbedGeneratorBase);

    _this = _super.call(this, props);

    _this.onHeightChange = event => {
      var height = parseInt(event.target.value.replace(/\D/g, ''), 10) || 0;

      _this.setState({
        height: height ? height.toString() : '',
        width: height ? Math.round(height * ASPECT_RATIO).toString() : ''
      });
    };

    _this.onWidthChange = event => {
      var width = parseInt(event.target.value.replace(/\D/g, ''), 10) || 0;

      _this.setState({
        height: width ? Math.round(width / ASPECT_RATIO).toString() : '',
        width: width ? width.toString() : ''
      });
    };

    _this.getEmbedIframeText = () => {
      var _this$state = _this.state,
          height = _this$state.height,
          width = _this$state.width;
      return _this.props.getEmbedIframeText(_this.props.oid, height, width);
    };

    _this.wait = function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(msec) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                return _context.abrupt("return", new Promise(resolve => setTimeout(() => resolve(), msec)));

              case 1:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();

    _this.copyToClipboard = function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(_) {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this.textArea.select();

                document.execCommand('copy');

                _this.setState({
                  showCopySuccessMessage: true
                });

                _context2.next = 5;
                return _this.wait(COPY_SUCCESS_MESSAGE_TIMEOUT_IN_MSEC);

              case 5:
                _this.setState({
                  showCopySuccessMessage: false
                });

                _this.props.onCopy();

              case 7:
              case "end":
                return _context2.stop();
            }
          }
        }, _callee2);
      }));

      return function (_x2) {
        return _ref2.apply(this, arguments);
      };
    }();

    _this.state = {
      height: DEFAULT_IFRAME_HEIGHT,
      width: DEFAULT_IFRAME_WIDTH,
      showCopySuccessMessage: false
    };
    return _this;
  }

  _createClass(EmbedGeneratorBase, [{
    key: "render",
    value: function render() {
      var _this$props$localized = this.props.localizedMessages,
          headerText = _this$props$localized.headerText,
          heightText = _this$props$localized.heightText,
          widthText = _this$props$localized.widthText,
          pixelText = _this$props$localized.pixelText,
          copyButtonText = _this$props$localized.copyButtonText,
          copyButtonSuccessText = _this$props$localized.copyButtonSuccessText,
          dimensionsTitleText = _this$props$localized.dimensionsTitleText,
          codeTitleText = _this$props$localized.codeTitleText,
          responsiveDimensionsText = _this$props$localized.responsiveDimensionsText,
          learnMoreText = _this$props$localized.learnMoreText;
      return React.createElement(EmbedGeneratorModal, null, React.createElement(CloseButton, {
        onClick: this.props.onCloseModal
      }, React.createElement(CloseIcon, null, React.createElement(CloseIconSvg, null))), React.createElement(HeaderText, null, headerText), React.createElement(SubtitleText, null, dimensionsTitleText), React.createElement(DimensionDiv, null, this.props.onlyResponsiveDimensionsAllowed ? React.createElement(DimensionsExplainerText, null, responsiveDimensionsText, this.props.learnMoreUrl && learnMoreText ? React.createElement(React.Fragment, null, ' ', React.createElement(DimensionsExplainerLearnMoreLink, {
        url: this.props.learnMoreUrl,
        text: learnMoreText
      })) : null) : React.createElement(React.Fragment, null, React.createElement("label", {
        htmlFor: "embed-height-input"
      }, heightText, ":", React.createElement(DimensionInput, {
        id: "embed-height-input",
        value: this.state.height,
        onChange: this.onHeightChange,
        maxLength: 4
      }), pixelText), React.createElement("label", {
        htmlFor: "embed-width-input"
      }, widthText, ":", React.createElement(DimensionInput, {
        id: "embed-width-input",
        value: this.state.width,
        onChange: this.onWidthChange,
        maxLength: 4
      }), pixelText))), React.createElement(SubtitleText, null, codeTitleText), React.createElement(EmbedCodeTextArea, {
        ref: textArea => this.textArea = textArea,
        value: this.getEmbedIframeText(),
        "data-lookup": "viewpage-embed-textarea"
      }), React.createElement(CopyToClipBoardButton, {
        id: "embed-clipboard-copy",
        onClick: this.copyToClipboard,
        "data-lookup": "viewpage-copy-embed-to-clipboard"
      }, this.state.showCopySuccessMessage ? copyButtonSuccessText : copyButtonText));
    }
  }]);

  return EmbedGeneratorBase;
}(React.PureComponent);
export var EmbedGenerator = props => {
  return React.createElement(ModalBase, {
    onCloseModal: props.onCloseModal
  }, React.createElement(EmbedGeneratorBase, props));
};