/**
 * This file was copied from frontend-packages/tools/ci/scripts/lokalise/templates/locales.index.ts.template
 * If you want to make changes to this file, consider changing the original.
 **/
import { Messages } from '@lingui/core';
import * as localeDE from '../locale/de/messages';
import * as localeEN from '../locale/en/messages';
import * as localeES from '../locale/es/messages';
import * as localeFR from '../locale/fr/messages';
import * as localePT from '../locale/pt/messages';
import * as localeHU from '../locale/hu/messages';
import * as localeIT from '../locale/it/messages';
import * as localeJA from '../locale/ja/messages';
import * as localeKO from '../locale/ko/messages';
import * as p from 'make-plural/plurals';

export type MessageCatalog = { [language: string]: Messages };
export const catalogs: MessageCatalog = {
    de: localeDE.messages,
    en: localeEN.messages,
    es: localeES.messages,
    fr: localeFR.messages,
    pt: localePT.messages,
    hu: localeHU.messages,
    it: localeIT.messages,
    ja: localeJA.messages,
    ko: localeKO.messages,
};

export const plurals: { [languageCode: string]: { plurals: Function } } = {
    en: { plurals: p.en },
    de: { plurals: p.de },
    es: { plurals: p.es },
    fr: { plurals: p.fr },
    pt: { plurals: p.pt },
    hu: { plurals: p.hu },
    it: { plurals: p.it },
    ja: { plurals: p.ja },
    ko: { plurals: p.ko },
};

export default catalogs;
