import * as React from 'react';
export function playIcon() {
  return React.createElement("svg", {
    style: {
      marginLeft: 14
    },
    xmlns: "http://www.w3.org/2000/svg",
    width: "58",
    height: "71",
    viewBox: "0 0 58 71"
  }, React.createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M58 35.046c0 1.746-1.769 3.002-1.769 3.002L6.577 69.018C2.958 71.405 0 69.635 0 65.11V4.976C0 .438 2.958-1.32 6.583 1.063l49.654 30.981C56.23 32.044 58 33.3 58 35.046z"
  }));
}
export function playIconSmall() {
  return React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "20",
    height: "22",
    viewBox: "0 0 20 22"
  }, React.createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M18.953 9.64c1.396.751 1.396 1.969 0 2.72L2.527 21.194C1.131 21.946 0 21.263 0 19.663V2.337C0 .739 1.132.054 2.527.804L18.953 9.64z"
  }));
}
export function restartIcon() {
  return React.createElement("svg", {
    style: {
      marginLeft: -9
    },
    xmlns: "http://www.w3.org/2000/svg",
    width: "69",
    height: "60",
    viewBox: "0 0 69 60"
  }, React.createElement("path", {
    fill: "#FFF",
    fillOpacity: ".8",
    fillRule: "nonzero",
    d: "M38.876 0C22.61 0 9.389 13.029 8.99 29.25H0l13.458 15 13.456-15H16.467C16.861 17.173 26.74 7.5 38.876 7.5c12.387 0 22.428 10.074 22.428 22.5s-10.04 22.5-22.428 22.5a22.273 22.273 0 0 1-13.271-4.365l-5.144 5.503A29.721 29.721 0 0 0 38.876 60C55.392 60 68.78 46.57 68.78 30S55.392 0 38.876 0"
  }));
}
export function restartIconSmall() {
  return React.createElement("svg", {
    style: {
      marginLeft: -9
    },
    xmlns: "http://www.w3.org/2000/svg",
    width: "22",
    height: "20",
    viewBox: "0 0 69 60"
  }, React.createElement("path", {
    fill: "#FFF",
    fillOpacity: ".8",
    fillRule: "nonzero",
    d: "M38.876 0C22.61 0 9.389 13.029 8.99 29.25H0l13.458 15 13.456-15H16.467C16.861 17.173 26.74 7.5 38.876 7.5c12.387 0 22.428 10.074 22.428 22.5s-10.04 22.5-22.428 22.5a22.273 22.273 0 0 1-13.271-4.365l-5.144 5.503A29.721 29.721 0 0 0 38.876 60C55.392 60 68.78 46.57 68.78 30S55.392 0 38.876 0"
  }));
}