import * as React from 'react';
import { t } from '@lingui/macro';

import {
    Toolbar,
    ShareButton,
    ReportButton,
    LikeButton,
    CTA as CTAComponent,
    API,
    logAndRedirect,
    SocialShareModal,
    ModalManager,
    CreatorBlockComponent,
    EmbedButton,
    ViewsButton,
} from '@prezi/viewer-common';
import { glassboxWebPrezigram, glassboxWebJs } from '@prezi/client-logger-module';

import { DesignPageContext, DesignViewPageModal } from '../../context';
import { getCurrentLanguage } from '../../i18n';
import { User } from '../../models/';
import { isActive } from '../../features/Features';

import { Card, CardBody, CardHeader, DesignDescription, DesignTitle } from './Styles';
import { CurationButtonComponent } from './CurationButtonComponent';

export interface DesignToolboxProps {
    title: string;
    isAuthenticated: boolean;
    isMobile: boolean;
    description: string;
    createdAt: string;
    oid: string;
    likedByMe: boolean;
    likesCount: number;
    userData: User;
    modalManager: ModalManager;
    viewLinkId?: string;
    setActiveModal: (modal: DesignViewPageModal) => void;
    makeACopyRedirectUrl: string;
    canMakeCopy: boolean;
    hasPrivateSharing: boolean;
    isPrivate: boolean;
    viewCount: number;
}

export const convertDateToString = (dateString: string) => {
    const language = getCurrentLanguage();
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString(language, options);
};

export const Toolbox: React.FunctionComponent<DesignToolboxProps> = (props: DesignToolboxProps) => {
    const shareButton = (
        <ShareButton
            key="sharebutton"
            isMobile={props.isMobile}
            title={props.title}
            tooltipText={t`Share`}
            onClick={(_?: React.SyntheticEvent) => {
                glassboxWebPrezigram.logOpenSocialShare({
                    prezi_oid: props.oid,
                    social_media_platform: 'OPEN_SHARE_NAV',
                });
                // @ts-ignore
                const mobileAndHasShare = props.isMobile && navigator && navigator.share;
                if (mobileAndHasShare) {
                    glassboxWebPrezigram.logSharePublish({ prezi_oid: props.oid, share_type: 'MOBILE' });
                    // @ts-ignore
                    navigator.share({
                        title: props.title,
                        url: window.location.href,
                    });
                } else {
                    props.modalManager.triggerModal(SocialShareModal.SOCIAL_SHARE_MODAL_NAME);
                }
            }}
        />
    );

    const reportButton = (
        <ReportButton
            key="reportbutton"
            isMobile={props.isMobile}
            tooltipText={t`Report`}
            onClick={(e?: React.SyntheticEvent) => {
                if (e) {
                    e.preventDefault();
                }
                window.open(`/contact/abuse/${props.oid}/`, '_blank');
            }}
        />
    );

    const shouldHideEmbedButton = typeof props.viewLinkId === 'undefined' && props.isPrivate;
    const embedButton = !shouldHideEmbedButton ? (
        <EmbedButton
            onClick={() => {
                glassboxWebJs.logOpenEmbedShare({ prezi_oid: props.oid });
                props.setActiveModal(DesignViewPageModal.EMBED_MODAL);
            }}
            isMobile={false}
            tooltipText={t`Embed`}
        />
    ) : null;

    const viewsButton = <ViewsButton viewCount={props.viewCount} tooltipText={t`Views`} />;

    const likeButton = (
        <LikeButton
            key="likebutton"
            id="design-likes"
            className="like-button"
            oid={props.oid}
            isAuthenticated={props.isAuthenticated}
            hasLiked={props.likedByMe}
            likesCount={props.likesCount}
            onLike={() => {
                glassboxWebPrezigram.logLikePublishPage({ prezi_oid: props.oid });
                return API.Likes.like(props.oid, props.viewLinkId);
            }}
            onUnlike={() => {
                glassboxWebPrezigram.logUnlikePublishPage({ prezi_oid: props.oid });
                return API.Likes.unlike(props.oid, props.viewLinkId);
            }}
            onLoggedOutLike={() => {
                const redirectUrl = isActive('ua-retest-ctas-lead-to-freemium-on-view-pages')
                    ? '/pricing/'
                    : '/signup/';
                const logPromise: Promise<boolean> = glassboxWebPrezigram.logLikePublishPage({ prezi_oid: props.oid });
                logAndRedirect(logPromise, redirectUrl);
            }}
            tooltipText={t`Likes`}
        />
    );

    let ctaLink: string;
    if (props.isAuthenticated) {
        ctaLink = '/dashboard/next/#/designs';
    } else {
        ctaLink = isActive('ua-retest-ctas-lead-to-freemium-on-view-pages') ? '/pricing/' : '/signup/';
    }
    const createYourOwnText = 'Create your own';
    const ctaInnerText = t`Create your own`;
    const createYourOwnCTA = (
        <CTAComponent
            key="createYourOwnCTA"
            onClick={() => {
                glassboxWebPrezigram.logClickCta({
                    cta_location: 'Toolbar',
                    cta_type: 'BUTTON',
                    cta_text: createYourOwnText,
                    target_url: ctaLink,
                });
                return true;
            }}
            innerText={ctaInnerText}
            href={ctaLink}
            className={'create-your-own-button'}
        />
    );

    const canCopyIfPrivate = props.isPrivate ? props.hasPrivateSharing : true;
    const canBeCopied = props.canMakeCopy && !!props.makeACopyRedirectUrl && canCopyIfPrivate;
    const makeACopyCTA = canBeCopied ? (
        <CTAComponent
            innerText={t`Make a copy`}
            onClick={() => {
                glassboxWebJs.logCopyDesign({
                    copy_prezi_oid: props.oid,
                    copy_prezi_source: 'VIEW_PAGE',
                });
            }}
            href={props.makeACopyRedirectUrl || ''}
            openNewTab={true}
            isGray={true}
            key="makeACopyCTA"
            className={'make-a-copy-button'}
        />
    ) : null;

    const curationButton = isActive('allow-content-curation') ? <CurationButtonComponent key="curationbutton" /> : null;

    return (
        <Card className="metadata-block shadow-sm" data-lookup="design-toolbox">
            <CardHeader>
                <DesignTitle data-lookup="design-title">{props.title}</DesignTitle>
                <Toolbar
                    leftChildren={[viewsButton, likeButton, shareButton, embedButton, reportButton, curationButton]}
                    rightChildren={[makeACopyCTA, createYourOwnCTA]}
                />
            </CardHeader>
            <CardBody>
                <CreatorBlockComponent
                    owner={props.userData}
                    lastModified={`${t`Created`} ${convertDateToString(props.createdAt)}`}
                />
                <DesignDescription>{props.description}</DesignDescription>
            </CardBody>
        </Card>
    );
};

export const WrappedToolbox = () => {
    return (
        <DesignPageContext.Consumer>
            {context => {
                return (
                    <Toolbox
                        title={context.presentationMetadata.title}
                        isAuthenticated={context.isAuthenticated}
                        isMobile={context.isMobile}
                        description={context.presentationMetadata.description}
                        createdAt={context.presentationMetadata.createdAt}
                        oid={context.presentationMetadata.oid}
                        userData={context.presentationMetadata.owner}
                        likedByMe={context.presentationMetadata.likedByMe}
                        likesCount={context.presentationMetadata.likeCount}
                        modalManager={context.modalManager}
                        viewLinkId={context.linkID ? context.linkID : undefined}
                        setActiveModal={context.setActiveModal}
                        makeACopyRedirectUrl={context.presentationMetadata.makeACopyRedirectUrl}
                        canMakeCopy={context.presentationMetadata.userPermissions?.canMakeCopy}
                        hasPrivateSharing={context.hasPrivateSharing}
                        isPrivate={context.presentationMetadata.privacy === 'private'}
                        viewCount={context.presentationMetadata.viewCount}
                    />
                );
            }}
        </DesignPageContext.Consumer>
    );
};
