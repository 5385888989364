import "core-js/modules/es.array.iterator";
import "core-js/modules/es.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); return Constructor; }

import * as React from 'react';
export var ModalManager = function () {
  function ModalManager() {
    _classCallCheck(this, ModalManager);

    this.modals = new Map();
  }

  _createClass(ModalManager, [{
    key: "triggerModal",
    value: function triggerModal(key) {
      var modalItem = this.modals.get(key);

      if (!modalItem) {
        throw new Error('Modal does not exist!');
      }

      if (modalItem.ref.current) {
        modalItem.ref.current.open();
      }
    }
  }, {
    key: "getModals",
    value: function getModals() {
      return this.modals;
    }
  }, {
    key: "registerModal",
    value: function registerModal(key, ModalComponent, modalProps) {
      var modalRef = React.createRef();
      var modalComponent = React.createElement(ModalComponent, _objectSpread(_objectSpread({}, modalProps), {}, {
        ref: modalRef
      }));
      var modal = {
        ref: modalRef,
        element: modalComponent
      };
      this.modals.set(key, modal);
    }
  }, {
    key: "renderModals",
    value: function renderModals() {
      var modalComponents = [];
      this.modals.forEach(modal => {
        modalComponents.push(modal.element);
      });
      return modalComponents;
    }
  }, {
    key: "removeAllModals",
    value: function removeAllModals() {
      this.modals = new Map();
    }
  }]);

  return ModalManager;
}();