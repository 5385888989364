import * as React from 'react';
import { I18nProvider } from '@lingui/react';
import { ThemeProvider } from 'styled-components';

import { DesignTheme, ModalManager, SocialShareModal } from '@prezi/viewer-common';
import { glassboxWebPrezigram } from '@prezi/client-logger-module';

import { Page as Root } from './components/PageComponent';
import { getCurrentLanguage, setupI18n } from './i18n';
import catalogs from './locale';
import * as Sentry from './sentry';
import { DesignProject, ExternalData } from './models';
import { DesignPageContext, DesignContext, DesignViewPageModal } from './context';
import * as Modals from './components/modals';
import * as Features from './features/Features';

Sentry.init();

class DesignViewPageProvider extends React.Component<ExternalData, DesignContext> {
    constructor(props: ExternalData) {
        super(props);

        Features.init(props.evaluatedFeatures || {});

        const modalManager = new ModalManager();
        modalManager.registerModal(
            SocialShareModal.SOCIAL_SHARE_MODAL_NAME,
            SocialShareModal,
            Modals.extractSocialShareModalProps(props.presentationMetadata),
        );
        this.state = {
            presentationMetadata: DesignProject.createFromAppData(props),
            isAuthenticated: props.isAuthenticated,
            isMobile: props.isMobile,
            linkID: props.linkID,
            modalManager,
            activeModal: DesignViewPageModal.NO_MODAL,
            setActiveModal: (modal: DesignViewPageModal) => {
                this.setState({ ...this.state, activeModal: modal });
            },
            hasPrivateSharing: props.hasPrivateSharing,
        };
    }

    render() {
        return <DesignPageContext.Provider value={this.state}>{this.props.children}</DesignPageContext.Provider>;
    }
}

export const DesignViewPageApp: React.FC<ExternalData> = (appData: ExternalData) => {
    const language = getCurrentLanguage();

    setupI18n(language, catalogs);

    glassboxWebPrezigram.logLoadedPublicPublishPage({
        prezi_oid: appData.presentationMetadata.oid,
        viewpage_mode: 'PUBLIC_VIEW_PAGE',
    });

    return (
        <DesignViewPageProvider {...appData}>
            <I18nProvider i18n={setupI18n()}>
                <ThemeProvider theme={DesignTheme}>
                    <Root />
                </ThemeProvider>
            </I18nProvider>
        </DesignViewPageProvider>
    );
};
