function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Avatar } from '@prezi/avatar';
import { Title } from './Common';
import { ResponsiveMode } from '../models/common';
import { boldTextStyle, baseTextStyle } from './Styles';
export function MetadataRow(props) {
  var rowStyle = {
    display: 'flex',
    position: 'relative'
  };
  var avatarStyle = {};
  var rightBlockStyle = {
    marginLeft: '9px'
  };

  var ownerLineStyle = _objectSpread(_objectSpread({}, boldTextStyle()), {}, {
    letterSpacing: 1,
    fontSize: 15
  });

  var modifiedDateStyle = _objectSpread(_objectSpread({}, baseTextStyle()), {}, {
    letterSpacing: 1.2
  });

  var _props$owner = props.owner,
      username = _props$owner.username,
      firstName = _props$owner.firstName,
      lastName = _props$owner.lastName;
  return React.createElement("div", {
    className: "viewer-common-metadata-row",
    style: rowStyle
  }, React.createElement("div", {
    className: "viewer-common-owner-avatar",
    style: avatarStyle
  }, React.createElement(Avatar, {
    size: 36,
    username: username,
    firstName: firstName,
    lastName: lastName
  })), React.createElement("div", {
    className: "viewer-common-metadata-right-block",
    style: rightBlockStyle
  }, React.createElement("div", {
    style: ownerLineStyle
  }, props.byOwner), React.createElement("div", {
    style: modifiedDateStyle
  }, props.lastModifiedDateString)));
}
export function MetadataBlock(props) {
  var margin = 0;

  switch (props.responsiveMode) {
    case ResponsiveMode.LARGE:
      margin = 60;
      break;

    case ResponsiveMode.MIDDLE:
      margin = 30;
      break;

    case ResponsiveMode.SMALL:
      margin = 12;
      break;

    default:
      margin = 12;
      break;
  }

  var style = {
    position: 'absolute',
    top: margin,
    left: margin,
    right: margin
  };
  return React.createElement("div", {
    className: "viewer-common-metadata-block",
    style: style
  }, React.createElement(Title, {
    title: props.title,
    responsiveMode: props.responsiveMode
  }), React.createElement(ReactCSSTransitionGroup, {
    transitionName: "viewer-common-metadata",
    transitionAppear: true,
    transitionAppearTimeout: 500,
    transitionEnter: false,
    transitionLeave: false
  }, React.createElement(MetadataRow, {
    byOwner: props.byOwner,
    lastModifiedDateString: props.lastModifiedDateString,
    owner: props.owner
  })));
}