export class User {
    public firstName!: string;
    public lastName!: string;
    public username!: string;
    public publicDisplayName!: string;
    public isAuthenticated?: boolean;
    public oid?: string;

    // eslint-disable-next-line max-params
    constructor(firstName?: string, lastName?: string, userName?: string, publicDisplayName?: string, oid?: string) {
        this.firstName = firstName || '';
        this.lastName = lastName || '';
        this.username = userName || '';
        this.publicDisplayName = publicDisplayName || '';
        this.oid = oid || '';
    }
}
