export enum IRequestState {
    NONE,
    LOADING,
    SUCCESS,
    ERROR,
}

export interface Response<PayloadType> {
    status: IRequestState;
    data?: PayloadType;
}

export enum ResponsiveMode {
    EXTRA_SMALL = 'extraSmall', // [0; WindowSizeBreakpoints.SMALL[
    SMALL = 'small', ///////////// [WindowSizeBreakpoints.SMALL; WindowSizeBreakpoints.MEDIUM[
    MEDIUM = 'medium', /////////// [WindowSizeBreakpoints.MEDIUM; WindowSizeBreakpoints.LARGE[
    LARGE = 'large', ///////////// [WindowSizeBreakpoints.LARGE; +∞[
    DEFAULT = 'default',
}

// These breakpoints value are included in the size type they describe.
//
// For example, a window size of 768px will be considered SMALL.
// In javacript, it translates to: SMALL <= smallWindow < MEDIUM
export const WindowSizeBreakpoints = {
    XSMALL: 434,
    SMALL: 768,
    MEDIUM: 992,
    LARGE: 1200,
};

// We use "not (min-width: ...)" instead of "(max-width: ...)" to avoid the overlapping of the 2 media queries.
// (See https://stackoverflow.com/a/13649011 for more info)
export const CssMedia = {
    GT_OR_EQ_XSMALL: `@media (min-width: ${WindowSizeBreakpoints.XSMALL}px)`,
    LT_XSMALL: `@media not all and (min-width: ${WindowSizeBreakpoints.XSMALL}px)`,

    GT_OR_EQ_SMALL: `@media (min-width: ${WindowSizeBreakpoints.SMALL}px)`,
    LT_SMALL: `@media not all and (min-width: ${WindowSizeBreakpoints.SMALL}px)`,

    GT_OR_EQ_MEDIUM: `@media (min-width: ${WindowSizeBreakpoints.MEDIUM}px)`,
    LT_MEDIUM: `@media not all and (min-width: ${WindowSizeBreakpoints.MEDIUM}px)`,

    GT_OR_EQ_LARGE: `@media (min-width: ${WindowSizeBreakpoints.LARGE}px)`,
    LT_LARGE: `@media not all and (min-width: ${WindowSizeBreakpoints.LARGE}px)`,
};

export const ASPECT_RATIO = (10 / 16) * 100;

export enum KEYCODES {
    ESCAPE = 27,
    ENTER = 13,
    SPACE = 32,
    COMMA = 188,
}
