import React from 'react';
import styled from 'styled-components';
var ViewOnPreziContainer = styled.div.withConfig({
  displayName: "ViewOnPrezi__ViewOnPreziContainer",
  componentId: "sc-1hfs0f-0"
})(["position:absolute;bottom:4px;left:4px;border-radius:4px;color:white;padding:8px;transition:background 300ms linear;cursor:pointer;&:hover{background:rgba(0,0,0,0.56);}@media only screen and (min-width:768px){bottom:26px;left:22px;}"]);
export var ViewOnPrezi = props => {
  return React.createElement(ViewOnPreziContainer, {
    onClick: props.onLinkClick,
    dangerouslySetInnerHTML: {
      __html: props.localizedMessage
    }
  });
};