import styled from 'styled-components';
import { Colors } from '@prezi/dragon';
import { TooltipDiv } from '../Tooltip/Styles';
export var ModalWrapper = styled.div.withConfig({
  displayName: "Styles__ModalWrapper",
  componentId: "sc-1ncp0xa-0"
})(["color:", ";width:500px;height:300px;background:white;border-radius:4px;box-shadow:0px 6px 15px rgba(21,34,53,0.3);position:relative;padding:32px;"], Colors.neutral600);
export var ModalTitle = styled.h2.withConfig({
  displayName: "Styles__ModalTitle",
  componentId: "sc-1ncp0xa-1"
})(["font-weight:600;font-size:24px;line-height:30px;margin-bottom:32px;"]);
export var SubHeading = styled.h3.withConfig({
  displayName: "Styles__SubHeading",
  componentId: "sc-1ncp0xa-2"
})(["font-style:normal;font-weight:bold;font-size:13px;line-height:16px;letter-spacing:0.5px;text-transform:uppercase;margin-bottom:14px;"]);
export var SocialButtonsWrapper = styled.div.withConfig({
  displayName: "Styles__SocialButtonsWrapper",
  componentId: "sc-1ncp0xa-3"
})(["display:flex;position:relative;margin-bottom:40px;"]);
export var ShareLinkWrapper = styled.div.withConfig({
  displayName: "Styles__ShareLinkWrapper",
  componentId: "sc-1ncp0xa-4"
})(["display:flex;width:100%;position:relative;border:1px solid ", ";border-radius:4px;&:hover,&:focus-within{border-color:", ";}& > ", ".top{position:absolute;top:-32px;left:50%;transform:translateX(-50%);}"], Colors.neutral100, props => props.theme.buttonColorHover, TooltipDiv);
export var LinkInput = styled.input.withConfig({
  displayName: "Styles__LinkInput",
  componentId: "sc-1ncp0xa-5"
})(["background:#ffffff;border:none;box-sizing:border-box;border-radius:4px;width:100%;padding:6px 8px;font-size:14px;height:32px;text-overflow:ellipsis;:focus{outline:none;}"]);
export var CopyLinkButton = styled.button.withConfig({
  displayName: "Styles__CopyLinkButton",
  componentId: "sc-1ncp0xa-6"
})(["right:0;background:", ";outline:none;border-radius:4px;border:none;font-weight:600;font-size:14px;line-height:24px;text-align:center;letter-spacing:0.5;padding:4px 16px;&:hover{background:", ";}"], Colors.neutral100, Colors.neutral200);