import * as React from 'react';
import { Icon } from '../Icon';
import { DesktopReportButton, MobileReportButton } from './Styles';
export var ReportButton = props => {
  var altText = props.alt || 'Button to report this content';
  return props.isMobile ? React.createElement(MobileReportButton, {
    onClick: props.onClick
  }, React.createElement(Icon, {
    iconUrl: "report_icon.svg",
    alt: altText
  })) : React.createElement(DesktopReportButton, {
    onClick: props.onClick,
    "data-toggle": "tooltip",
    title: props.tooltipText,
    "data-placement": "bottom"
  }, React.createElement(Icon, {
    iconUrl: "report_icon.svg",
    alt: altText
  }));
};