import * as Sentry from '@sentry/browser';

const SENTRY_DSN = { dsn: 'https://bf9989d3412f454790c5f49d9ea22edf@sentry.io/3498979' };

export const init = () => {
    const hostname = window.location.hostname;
    if (hostname !== 'localhost' && !hostname.endsWith('prezi.dev')) {
        Sentry.init(SENTRY_DSN);
    }
};
