import styled from 'styled-components';
export var Toolbar = styled.div.withConfig({
  displayName: "Styles__Toolbar",
  componentId: "sc-5taeew-0"
})(["a{margin-right:6px;}"]);
export var ToolbarWrapper = styled.div.attrs({
  className: 'toolbar-wrapper'
}).withConfig({
  displayName: "Styles__ToolbarWrapper",
  componentId: "sc-5taeew-1"
})(["display:flex;justify-content:space-between;align-items:center;height:54px;width:100%;@media (min-width:768px) and (max-width:992px){margin-bottom:6px;}@media screen and (max-width:576px),(min-width:992px) and (max-width:1200px){display:block;height:auto;}& > div{height:54px;display:inline-flex;align-items:center;@media screen and (max-width:576px),(min-width:992px) and (max-width:1200px){width:100%;}&:empty{display:none;}}& > div:nth-child(2) a{margin:0 3px;@media screen and (max-width:576px),(min-width:992px) and (max-width:1200px){width:100%;}}"]);