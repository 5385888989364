import styled, { StyledComponent } from 'styled-components';
import { Colors } from '@prezi/dragon';

export const Card: StyledComponent<'div', any, {}, never> = styled.div`
    background-color: white;
    border-radius: 3px;
    position: relative;
    margin-bottom: 0px;

    @media (min-width: 768px) {
        margin-bottom: 12px;
    }

    @media (min-width: 992px) {
        margin-bottom: 30px;
    }
`;

export const CardHeader = styled.div.attrs({
    className: 'video-view-card-header',
})`
    border-bottom: 1px solid ${Colors.neutral80};
    padding: 0px 15px 12px 15px;

    @media (min-width: 768px) {
        padding: 0px 24px 12px 24px;
    }

    @media (min-width: 992px) {
        padding: 24px 24px 18px 24px;
    }
`;

export const CardBody = styled.div`
    padding: 24px 24px 30px 24px;
    height: auto;

    @media screen and (max-width: 992px) {
        overflow: hidden;
        padding: 15px;
        }
    }
`;

export const DesignTitle = styled.h1.attrs({
    className: 'design-title',
})`
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: ${Colors.neutral700};
    padding-top: 18px;
    padding-bottom: 6px;
    margin-bottom: 0px;
    overflow-wrap: break-word;

    @media (max-width: 768px) {
        padding-top: 24px;
        padding-bottom: 12px;
    }

    @media (min-width: 992px) {
        font-weight: bold;
        font-size: 24px;
        padding-top: 0px;
        line-height: 30px;
    }
`;

export const DesignDescription = styled.p.attrs({
    className: 'design-description',
})`
    font-size: 16px;
    line-height: 24px;
    color: ${Colors.neutral500};
    margin-bottom: 12px;
    white-space: pre-wrap;
`;
