import * as React from 'react';
import { Label } from './Common';
import { ResponsiveMode } from '../models/common';
import { ProgressIndicator } from './ProgressIndicator';
import { centeringBlockStyle, foregroundStyle } from './Styles';
import { MetadataBlock } from './MetadataBlock';
export var LoadingOverlay = props => {
  return React.createElement("div", {
    className: "viewer-common-info-overlay-foreground",
    style: foregroundStyle
  }, React.createElement(MetadataBlock, {
    title: props.title,
    byOwner: props.byOwner,
    lastModifiedDateString: props.lastModifiedDateString,
    owner: props.owner,
    responsiveMode: props.responsiveMode
  }), React.createElement("div", {
    className: "viewer-common-info-overlay-centering",
    style: centeringBlockStyle
  }, React.createElement("div", {
    className: "viewer-common-info-overlay-center-block"
  }, props.responsiveMode === ResponsiveMode.SMALL ? React.createElement("div", null, React.createElement(ProgressIndicator, {
    size: 80,
    progressPercentage: props.loadPercentage || 0
  }), React.createElement(Label, {
    text: props.localizedMessages.loadingLabel
  })) : React.createElement("div", null, React.createElement(ProgressIndicator, {
    size: 168,
    progressPercentage: props.loadPercentage || 0
  }), React.createElement(Label, {
    text: props.localizedMessages.loadingLabel
  })))));
};