import "core-js/modules/es.array.from";
import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import "core-js/modules/web.dom-collections.iterator";
import "regenerator-runtime/runtime";

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

export var logAndRedirect = function () {
  var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(logPromise, targetUrl) {
    var timeoutDuration,
        glassboxTimeoutPromise,
        _args = arguments;
    return regeneratorRuntime.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            timeoutDuration = _args.length > 2 && _args[2] !== undefined ? _args[2] : 500;
            glassboxTimeoutPromise = new Promise(resolve => setTimeout(() => {
              resolve();
            }, timeoutDuration));
            return _context.abrupt("return", Promise.race([logPromise, glassboxTimeoutPromise]).then(() => window.location.assign(targetUrl)));

          case 3:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function logAndRedirect(_x, _x2) {
    return _ref.apply(this, arguments);
  };
}();
export var getRandomSubarray = (arr, subarrayLength) => {
  if (subarrayLength > arr.length) {
    throw new Error('Invalid number of elements provided. Subarray cannot have more elements than original array.');
  }

  var shuffled = Array.from(arr);
  var rightIdx = arr.length;
  var randomIdx;

  while (rightIdx) {
    randomIdx = Math.floor(Math.random() * rightIdx--);
    var _ref2 = [shuffled[randomIdx], shuffled[rightIdx]];
    shuffled[rightIdx] = _ref2[0];
    shuffled[randomIdx] = _ref2[1];
  }

  return shuffled.slice(0, subarrayLength);
};
export var smoothScrollToTop = () => {
  var isSmoothScrollSupported = ('scrollBehavior' in document.documentElement.style);

  if (isSmoothScrollSupported) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  } else if (typeof jQuery !== 'undefined') {
    $('html').animate({
      scrollTop: 0
    }, 500);
  } else {
    window.scrollTo({
      top: 0
    });
  }
};