import * as React from 'react';

export interface MountDOMAsyncProps {
    id?: string;
    fallback: React.ReactNode;
    onMountingSuccess?: () => void;
    onMountingFailure?: () => void;
    fetchAndMount: (ref: HTMLDivElement | null) => Promise<void>;
}

export enum LoadResult {
    Pending,
    Success,
    Failure,
}

export class MountDOMAsync extends React.Component<MountDOMAsyncProps> {
    public appRef: React.RefObject<HTMLDivElement>;

    state = {
        loadResult: LoadResult.Pending,
    };

    constructor(props: MountDOMAsyncProps) {
        super(props);

        this.appRef = React.createRef();
    }

    async componentDidMount() {
        const { onMountingFailure, onMountingSuccess, fetchAndMount } = this.props;
        try {
            await fetchAndMount(this.appRef.current);
            this.setState({ loadResult: LoadResult.Success });
            if (onMountingSuccess) {
                onMountingSuccess();
            }
        } catch (_) {
            this.setState({ loadResult: LoadResult.Failure });
            if (onMountingFailure) {
                onMountingFailure();
            }
        }
    }

    render() {
        const fallback: React.ReactNode = this.state.loadResult !== LoadResult.Success ? this.props.fallback : null;
        return (
            <>
                {fallback}
                <div id={this.props.id} ref={this.appRef} />
            </>
        );
    }
}

export default MountDOMAsync;
