export var invokeAdminToolsUI = () => {
  window.dispatchEvent(new CustomEvent('prezi:admin:tools:invoke'));
};
export var reinitializeAdminToolsUI = (oid, title) => {
  window.dispatchEvent(new CustomEvent('prezi:admin:tools:reinitialize', {
    detail: {
      title,
      oid
    }
  }));
};
export var listenToAdminToolsTagStatusEvent = onTagStatusUpdated => {
  window.addEventListener('prezi:admin:tools:hidden:tags:loaded', event => {
    if (!(event instanceof CustomEvent)) {
      return;
    }

    var parsedEvent = event;

    if (parsedEvent.detail.hasTags) {
      onTagStatusUpdated(true);
    } else {
      onTagStatusUpdated(false);
    }
  });
};